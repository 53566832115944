import moment from 'moment'

export const data = () => ({
  isDebug: true,
  loading: false,
  vendorID: 0,
  vendorGlobalID: 0,
  formatDateFn: function(value) {
    return value != null ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY') : ''
  },

  filters: {
    show: false
  },

  region: {},

  rows: [],

  columns: [
    {
      field: 'name',
      label: 'Name',
      aria: 'Name',
      width: '10%',
      sortable: true,
      searchable: true
    },
    {
      field: 'description',
      label: 'Description',
      aria: 'Description',
      width: '30%',
      sortable: true,
      searchable: true
    }
  ],

  styles: {
    filterButton: {
      float: 'right',
      marginTop: '4px'
    }
  }
})
