import _get from 'lodash/get'
import { vendorList } from '@/services/Vendors/store'
import { vendorServiceType } from '@/services/Vendors/VendorServiceTypes/store'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const name = _get(entry, 'vendorServiceType.name', '')
      const descripition = _get(entry, 'vendorServiceType.description', '')

      return {
        name: name,
        descripition: descripition
      }
    })

    this.rows = rows
  },

  async getVendorGlobalID() {
    await vendorList
      .dispatch('getVendorById', {
        vendorID: this.vendorID
      })
      .then(({ result }) => {
        if (result) {
          this.vendorGlobalID = result.vendorGlobalID
          if (this.isDebug == true) console.debug('getVendorGlobalID=' + this.vendorGlobalID)
        }
      })
  },

  async loadVendorServiceTypes() {
    if (this.isDebug == true) console.debug('in loadVendorServiceTypes()...')

    await this.getVendorGlobalID()

    await vendorServiceType
      .dispatch('getVendorServiceTypeList', {
        vendorGlobalID: this.vendorGlobalID
      })
      .then(({ list }) => {
        this.determineRows(list)
      })
  },

  reload() {
    this.loading = true

    this.vendorID = this.$route.params.id || 0

    this.rows = []
    this.loadVendorServiceTypes()

    this.loading = false
  }
}
