<template>
  <PageContentLayoutOnly :aria-label="$t('vendorServiceType.title')" role="region">
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'

export default {
  name: 'VendorServiceTypes',
  components: {
    PageContentLayoutOnly,
    ModernTable
  },

  data,
  methods,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  watch: {},

  mounted() {
    this.reload()
  },

  i18n: {
    messages: {
      en: { vendorServiceType: { title: 'Service Types' } }
    }
  }
}
</script>

<style></style>
