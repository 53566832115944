/*
  import { vendorServiceType } from '@/services/Vendors/VendorServiceTypes/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'

import { notifyProblem } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  /*
    const { list } = await vendorServiceType.dispatch ('getVendorServiceTypeList', {
      vendorID
    });
  */
  async getVendorServiceTypeList({}, { vendorGlobalID }) {
    console.log('in getVendorServiceTypeList...')
    try {
      const result = await kms.get('/Vendors/VendorGlobalServiceType/List', {
        params: {
          vendorGlobalID
        }
      })

      if (isDebug == true) console.debug('getVendorServiceTypeList log=' + JSON.stringify(result))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The vendor service type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const vendorServiceType = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
